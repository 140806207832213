import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableDataSelectableEvent,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExportPcfsResponse,
  ProductsParams,
  useExportPcfs,
  useListProducts,
} from '../../../hooks/api/products';
import useNotificationContext from '../../../hooks/notification/useNotificationContext';
import ProductRegion from '../../../shared/components/product-region/ProductRegion';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { ToastSeverity } from '../../../shared/enums/toast-severity';
import { IProduct } from '../../../shared/interfaces/IProduct';
import ProductId from '../../products/components/ProductId';
import ProductName from '../../products/components/ProductName';
import ProductPcfValueColBody from '../../products/components/ProductPcfValue';
import { ProductFilters } from '../../products/interfaces/product-item';
import RegionFilter from './components/RegionsFilter';
import SuccessToastContent from './components/SuccessToastContent';
import styles from './ExportPcfsModal.module.css';

interface ExportPcfsModalProps extends DialogProps {
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
}

const ExportPcfsModal = (props: ExportPcfsModalProps): JSX.Element => {
  const { biogenicEmissionsMode, onHide, ...rest } = props;
  const { t } = useTranslation();
  const { notify, clear } = useNotificationContext();

  // Filter to be used for fetching products
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    statusFilter: [],
    regionsFilter: [],
    searchStr: '',
  });

  // For exporting selected products to csv
  const [selectedProductsToExportCsv, setSelectedProductsToExportCsv] = useState<IProduct[]>([]);

  // Params for the RQ Query to get products
  const params = useMemo(() => {
    const paramsValue: ProductsParams = {};
    if (productFilters.statusFilter.length) {
      paramsValue['status'] = productFilters.statusFilter.join(',');
    }

    if (productFilters.regionsFilter.length) {
      paramsValue['region'] = productFilters.regionsFilter.join(',');
    }

    if (productFilters.searchStr) {
      paramsValue['search'] = productFilters.searchStr.trim();
    }

    return paramsValue;
  }, [productFilters]);

  // RQ Query to get products
  const { isFetching, data: products } = useListProducts(params);

  // Filter products with status 'Complete' and selected regions
  const filteredProducts = products?.filter((product) => {
    return product.status === PCFRequestStatus.Complete;
  });

  const onDialogHide = () => {
    onHide();
    setProductFilters({ statusFilter: [], regionsFilter: [], searchStr: '' });
    setSelectedProductsToExportCsv([]);
  };

  // Callback for when RQ Mutation for exporting PCFs is successful
  const onExportPcfsSuccess = (data: ExportPcfsResponse) => {
    setSelectedProductsToExportCsv([]);

    // If there are any successfull exports, show success toast with the button, otherwise do nothing
    if (data.successCount) {
      notify({
        severity: ToastSeverity.SUCCESS,
        content: <SuccessToastContent response={data} clear={clear} />,
      });
    } else {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('toastMessages.exportPcfs.states.failed.title'),
        detail: t('toastMessages.exportPcfs.states.failed.detail'),
        life: 5000,
      });
    }
  };

  const onExportPcfsError = () => {
    notify({
      severity: ToastSeverity.ERROR,
      summary: t('toastMessages.exportPcfs.states.failed.title'),
      detail: t('toastMessages.exportPcfs.states.failed.detail'),
      life: 5000,
    });
  };

  const onExportPcfsSettled = () => {
    setSelectedProductsToExportCsv([]);
  };

  // RQ Mutation to export PCFs
  const { mutate: exportPcfs } = useExportPcfs({
    onSuccess: onExportPcfsSuccess,
    onError: onExportPcfsError,
    onSettled: onExportPcfsSettled,
  });

  // Function for updating the filter values
  const updateProductFilters = (newFilters: ProductFilters) => {
    const updatedFilters = { ...productFilters };

    if (newFilters.regionsFilter) {
      updatedFilters.regionsFilter = newFilters.regionsFilter;
    }

    setProductFilters(updatedFilters);
  };

  const isRowSelectable = (event: DataTableDataSelectableEvent) => {
    return event.data ? (event.data as IProduct).status === PCFRequestStatus.Complete : false;
  };

  const onSelectionChange = (e: DataTableSelectionMultipleChangeEvent<IProduct[]>) => {
    setSelectedProductsToExportCsv(e.value as IProduct[]);
  };

  const onClickExportPcfs = () => {
    // Get the product IDs of the selected products
    const productIds = selectedProductsToExportCsv.map((product) => {
      return product.product_id;
    });

    // Show an info toast when the export process starts
    notify({
      severity: ToastSeverity.INFO,
      summary: t('toastMessages.exportPcfs.states.exporting.title'),
      detail: t('toastMessages.exportPcfs.states.exporting.detail'),
      life: 5000,
    });

    // Make api call to get a signed URL for the CSV file
    exportPcfs({ productIds });

    // Hide the dialog
    onDialogHide();
  };

  const footerContent = (
    <div className='flex flex-row gap-2 justify-content-end w-full'>
      <Button label={t('exportPcfsDialog.buttons.cancel')} outlined onClick={onDialogHide} />
      <Button
        label={t('exportPcfsDialog.buttons.export')}
        disabled={selectedProductsToExportCsv.length === 0}
        onClick={onClickExportPcfs}
      />
    </div>
  );

  const productNameTemplate = (product: IProduct) => {
    return (
      <ProductName
        productId={product.product_id}
        productName={product.product_name}
        ownProductName={product.own_product_name}
      />
    );
  };

  const productIdTemplate = (product: IProduct) => {
    return <ProductId productId={product.product_cid} ownProductId={product.own_product_id} />;
  };

  const productRegionTemplate = (product: IProduct) => {
    return <ProductRegion product={product} />;
  };

  return (
    <Dialog
      header={t('exportPcfsDialog.title')}
      draggable={false}
      blockScroll
      footer={footerContent}
      style={{ width: '1000px', height: '800px' }}
      onHide={onDialogHide}
      {...rest}
    >
      <RegionFilter productFilters={productFilters} updateProductFilters={updateProductFilters} />

      <DataTable
        selectionMode='checkbox'
        selection={selectedProductsToExportCsv}
        onSelectionChange={onSelectionChange}
        isDataSelectable={isRowSelectable}
        disabled={isFetching}
        loading={isFetching}
        value={filteredProducts}
        scrollable
        pt={{
          thead: {
            className: 'z-2 !important',
          },
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
        className={styles['products-table']}
      >
        <Column
          selectionMode='multiple'
          header={t('exportPcfsDialog.selectAll')}
          headerStyle={{ width: '3rem' }}
        />
        <Column
          field='product_name'
          style={{ minWidth: '8rem', maxWidth: '16rem' }}
          body={productNameTemplate}
        />
        <Column
          field='product_cid'
          style={{ minWidth: '8rem', maxWidth: '12rem' }}
          body={productIdTemplate}
        />
        <Column field='region' body={productRegionTemplate} />
        <Column
          body={(product) => {
            return (
              <ProductPcfValueColBody
                product={product}
                biogenicEmissionsMode={biogenicEmissionsMode}
              />
            );
          }}
        />
      </DataTable>
    </Dialog>
  );
};

export default ExportPcfsModal;
