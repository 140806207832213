import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../hooks/auth/useAuth';

type ExportOrRequestPcfsButtonsProps = {
  setShowExportPcfsModal: (show: boolean) => void;
  setShowBulkRequestPcfsModal: (show: boolean) => void;
};

const ExportOrRequestPcfsButtons = ({
  setShowExportPcfsModal,
  setShowBulkRequestPcfsModal,
}: ExportOrRequestPcfsButtonsProps): JSX.Element | null => {
  const { user } = useAuth();
  const { t } = useTranslation();

  if (user?.company?.license === 'basic') {
    return null;
  }

  const onClickExportPcfs = () => {
    setShowExportPcfsModal(true);
  };

  const onClickRequestPcfs = () => {
    setShowBulkRequestPcfsModal(true);
  };

  return (
    <div className='flex flew-row w-full justify-content-end gap-2 mb-4'>
      <Button
        className='justify-content-center'
        outlined
        onClick={onClickExportPcfs}
        label={t('productsPage.bulkPcfExport')}
      />
      <Button
        className='justify-content-center'
        onClick={onClickRequestPcfs}
        label={t('productsPage.bulkPcfRequest')}
      />
    </div>
  );
};

export default ExportOrRequestPcfsButtons;
