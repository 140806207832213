import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CustomMultiSelect from '../../../../components/custom-multi-select/CustomMultiSelect';
import { PCFRequestRegionMap } from '../../../../shared/enums/pcf-request';
import { IDropdownItem } from '../../../../shared/interfaces/IDropdownItem';
import { ProductFilters } from '../../../products/interfaces/product-item';

type RegionFilterProps = {
  productFilters: ProductFilters;
  updateProductFilters: (filters: ProductFilters) => void;
};

const RegionFilter = ({ productFilters, updateProductFilters }: RegionFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const translatedRegions = useMemo(() => {
    const regions = Object.entries(PCFRequestRegionMap).map(([code]) => {
      return {
        name: t(`pcfRequest.${code}`),
        code,
      };
    });

    return [{ code: 'G', name: t('pcfRequest.global') }, ...regions];
  }, [PCFRequestRegionMap]);

  const setMultiSelectFilter = (selectedItems: IDropdownItem[], key: string) => {
    const codes = selectedItems.map((item) => {
      return item.code;
    });
    updateProductFilters({
      ...productFilters,
      [key]: codes,
    });
  };

  return (
    <div className='flex align-items-center justify-content-end px-4 gap-2'>
      <label className='font-medium'>{t('exportPcfsDialog.filters')}</label>
      <CustomMultiSelect
        options={translatedRegions}
        label={t('exportPcfsDialog.regions')}
        maxSelectedOptions={2}
        onApplyFilter={(selectedItems: IDropdownItem[]) => {
          setMultiSelectFilter(selectedItems, 'regionsFilter');
        }}
        onClearFilter={() => {
          setMultiSelectFilter([], 'regionsFilter');
        }}
        initialValues={productFilters.regionsFilter}
      />
    </div>
  );
};

export default RegionFilter;
