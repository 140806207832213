import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import { useRequestSustainableAlternative } from '../../../hooks/api/products';
import useNotificationContext from '../../../hooks/notification/useNotificationContext';
import { ToastSeverity } from '../../../shared/enums/toast-severity';

interface CheckForSustainableAlternativeModalProps extends DialogProps {
  productId: number;
}
const CheckForSustainableAlternativeModal = (
  props: CheckForSustainableAlternativeModalProps,
): JSX.Element => {
  const { productId, onHide, ...rest } = props;
  const { t } = useTranslation();
  const { notify } = useNotificationContext();

  const onSuccess = () => {
    notify({
      severity: ToastSeverity.SUCCESS,
      summary: t('toastMessages.checkForSustainableAlternative.states.success.title'),
      detail: t('toastMessages.checkForSustainableAlternative.states.success.detail'),
      life: 5000,
    });
  };

  const onError = () => {
    notify({
      severity: ToastSeverity.ERROR,
      summary: t('toastMessages.checkForSustainableAlternative.states.failure.title'),
      detail: t('toastMessages.checkForSustainableAlternative.states.failure.detail'),
      life: 5000,
    });
  };

  const { mutate: requestSustainableAlternative } = useRequestSustainableAlternative({
    onSuccess,
    onError,
  });

  const handleContactMeClick = () => {
    /* eslint-disable-next-line camelcase */
    requestSustainableAlternative({ product_id: productId });

    onHide();
  };

  const footerContent = (
    <div className='flex flex-row gap-2 justify-content-end w-full'>
      <Button
        label={t('checkForSustainableAlternativeAlternativeDialog.buttons.cancel')}
        outlined
        onClick={onHide}
      />
      <Button
        label={t('checkForSustainableAlternativeAlternativeDialog.buttons.contactMe')}
        onClick={handleContactMeClick}
      />
    </div>
  );

  return (
    <Dialog
      header={t('checkForSustainableAlternativeAlternativeDialog.title')}
      footer={footerContent}
      onHide={onHide}
      {...rest}
      draggable={false}
      blockScroll
      style={{ width: '600px' }}
    >
      <p>{t('checkForSustainableAlternativeAlternativeDialog.paragraph1')}</p>
      <p>{t('checkForSustainableAlternativeAlternativeDialog.paragraph2')}</p>
    </Dialog>
  );
};

export default CheckForSustainableAlternativeModal;
