import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableDataSelectableEvent,
  DataTableSelectAllChangeEvent,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsParams, useBulkRequestPcfs, useListProducts } from '../../../hooks/api/products';
import useNotificationContext from '../../../hooks/notification/useNotificationContext';
import ProductRegion from '../../../shared/components/product-region/ProductRegion';
import { MAX_BULK_PCF_REQUEST_SELECTION_LIMIT } from '../../../shared/constants/products.constant';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { ToastSeverity } from '../../../shared/enums/toast-severity';
import { IProduct } from '../../../shared/interfaces/IProduct';
import ProductId from '../../products/components/ProductId';
import ProductName from '../../products/components/ProductName';
import { ProductFilters } from '../../products/interfaces/product-item';
import styles from './BulkRequestPcfsModal.module.css';
import RegionFilter from './components/RegionsFilter';

interface BulkRequestPcfsModalProps extends DialogProps {
  productsToShowPendingDuringBulk: IProduct[];
  setProductsToShowPendingDuringBulk: (products: IProduct[]) => void;
}
const BulkRequestPcfsModal = (props: BulkRequestPcfsModalProps): JSX.Element => {
  const {
    productsToShowPendingDuringBulk,
    setProductsToShowPendingDuringBulk,
    onHide,
    visible,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { notify, clear } = useNotificationContext();
  const queryClient = useQueryClient();

  // Filter to be used for fetching products
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    statusFilter: [],
    regionsFilter: [],
    searchStr: '',
  });

  const [selectedProductsToRequestPcf, setSelectedProductsToRequestPcf] = useState<IProduct[]>([]);

  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] = useState(false);

  // Params for the RQ Query to get products
  const params = useMemo(() => {
    const paramsValue: ProductsParams = {};
    if (productFilters.statusFilter.length) {
      paramsValue['status'] = productFilters.statusFilter.join(',');
    }

    if (productFilters.regionsFilter.length) {
      paramsValue['region'] = productFilters.regionsFilter.join(',');
    }

    if (productFilters.searchStr) {
      paramsValue['search'] = productFilters.searchStr.trim();
    }

    return paramsValue;
  }, [productFilters]);

  // RQ Query to get products
  const { isFetching, data: products } = useListProducts(params);

  // RQ Mutation to bulk request pcf
  const { mutate: bulkRequestPcf } = useBulkRequestPcfs({
    onMutate: async () => {
      // When button is clicked, set the selected products to show pending during bulk request
      // 1. Make status "Pending"
      // 2. Set request_id to -1, so that Button and PCFs columns won't be rendered
      const optimisticallyUpdatedProducts: IProduct[] = selectedProductsToRequestPcf.map(
        (product) => {
          return {
            ...product,
            status: PCFRequestStatus.Pending,
            request_id: -1, // eslint-disable-line camelcase
          };
        },
      );

      // Update the productsToShowPendingDuringBulk state, so that we can show those products with status "Pending" in the ProductsPage
      setProductsToShowPendingDuringBulk(optimisticallyUpdatedProducts);

      setIsHeaderCheckboxSelected(false);
      setSelectedProductsToRequestPcf([]);
    },
    onSuccess: async (data) => {
      // Invalidate the query cache to refetch latest state of the products
      await queryClient.invalidateQueries({ queryKey: ['listProducts'] });
      // Clear all the existing toasts
      clear();

      if (data !== undefined && data !== null) {
        if (typeof data !== 'string') {
          // Desctructure the successCount and failureCount from the response and show toasts accordingly
          const { successCount, failureCount } = data;

          // Show success and error toasts based on the response
          if (successCount) {
            notify({
              severity: ToastSeverity.SUCCESS,
              detail: t('toastMessages.bulkRequestPcfs.states.success.detail', { successCount }),
            });
          }

          if (failureCount) {
            notify({
              severity: ToastSeverity.ERROR,
              detail: t('toastMessages.bulkRequestPcfs.states.failure.detail', { failureCount }),
            });
          }
        }
      }
    },
    onSettled: () => {
      setProductsToShowPendingDuringBulk([]);
    },
  });

  // Filter products with status 'Open' and with region
  const [filteredProducts, setFilterdProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    if (visible && products) {
      const filteredProducts = products.filter((product) => {
        const isBeingRequested = productsToShowPendingDuringBulk.find((pendingProduct) => {
          return pendingProduct.product_id === product.product_id;
        });

        return !product.status && product.region && !isBeingRequested;
      });
      setFilterdProducts(filteredProducts);
    }
  }, [visible, products, productsToShowPendingDuringBulk.length]);

  const onDialogHide = () => {
    setSelectedProductsToRequestPcf([]);
    setIsHeaderCheckboxSelected(false);
    onHide();
    setProductFilters({ statusFilter: [], regionsFilter: [], searchStr: '' });
  };

  // Function for updating the filter values
  const updateProductFilters = (newFilters: ProductFilters) => {
    const updatedFilters = { ...productFilters };

    if (newFilters.regionsFilter) {
      updatedFilters.regionsFilter = newFilters.regionsFilter;
    }

    setProductFilters(updatedFilters);
  };

  const isRowSelectable = (event: DataTableDataSelectableEvent) => {
    const isProductSelectable = event.data
      ? !(event.data as IProduct).status && (event.data as IProduct).region
      : false;

    const isMaxReached =
      selectedProductsToRequestPcf.length >= MAX_BULK_PCF_REQUEST_SELECTION_LIMIT;

    if (!isMaxReached) {
      return isProductSelectable;
    } else {
      const isProductSelected = selectedProductsToRequestPcf.find((product) => {
        return product.product_id === event.data.product_id;
      });

      return isProductSelectable && isProductSelected;
    }
  };

  const onSelectAllChange = (e: DataTableSelectAllChangeEvent) => {
    const numRemaining = MAX_BULK_PCF_REQUEST_SELECTION_LIMIT - selectedProductsToRequestPcf.length;

    if (e.checked) {
      // Find selectable products
      const selectableProducts =
        filteredProducts?.filter((product, index) => {
          return (
            selectedProductsToRequestPcf.indexOf(product) === -1 &&
            isRowSelectable({ data: product, index })
          );
        }) ?? [];

      // Get the first numRemaining selectable products
      if (numRemaining) {
        setSelectedProductsToRequestPcf([
          ...selectedProductsToRequestPcf,
          ...selectableProducts.slice(0, numRemaining),
        ]);
        setIsHeaderCheckboxSelected(true);
      }
    } else {
      setSelectedProductsToRequestPcf([]);
      setIsHeaderCheckboxSelected(false);
    }
  };

  const onSelectionChange = (e: DataTableSelectionMultipleChangeEvent<IProduct[]>) => {
    if (e.value.length <= MAX_BULK_PCF_REQUEST_SELECTION_LIMIT) {
      setSelectedProductsToRequestPcf(e.value);

      if (e.value.length < MAX_BULK_PCF_REQUEST_SELECTION_LIMIT) {
        setIsHeaderCheckboxSelected(false);
      } else {
        setIsHeaderCheckboxSelected(true);
      }

      if (filteredProducts?.length === e.value.length) {
        setIsHeaderCheckboxSelected(true);
      }
    }
  };

  const onClickRequest = () => {
    // Set the selected products to show pending during bulk request
    setProductsToShowPendingDuringBulk(selectedProductsToRequestPcf);

    // Get the product IDs of the selected products
    const productIds = selectedProductsToRequestPcf.map((product) => {
      return product.product_id;
    });

    // Show an info toast when the bulk request starts
    notify({
      severity: ToastSeverity.INFO,
      summary: t('toastMessages.bulkRequestPcfs.states.requesting.title'),
      detail: t('toastMessages.bulkRequestPcfs.states.requesting.detail'),
      life: 3000,
    });

    // Make API call
    bulkRequestPcf({ productIds });

    // Close the dialog immediately
    onHide();
  };

  const footerContent = (
    <div className='flex flex-row gap-2 justify-content-end w-full'>
      <Button label={t('bulkRequestPcfsDialog.buttons.cancel')} outlined onClick={onDialogHide} />
      <Button
        label={t('bulkRequestPcfsDialog.buttons.request')}
        disabled={selectedProductsToRequestPcf.length === 0}
        onClick={onClickRequest}
      />
    </div>
  );

  const productNameTemplate = (product: IProduct) => {
    return <ProductName productName={product.product_name} />;
  };

  const productIdTemplate = (product: IProduct) => {
    return <ProductId productId={product.product_cid} />;
  };

  const productRegionTemplate = (product: IProduct) => {
    return <ProductRegion product={product} />;
  };

  return (
    <Dialog
      header={t('bulkRequestPcfsDialog.title')}
      draggable={false}
      blockScroll
      footer={footerContent}
      style={{ width: '1000px', height: '800px' }}
      onHide={onDialogHide}
      visible={visible}
      {...rest}
    >
      <RegionFilter productFilters={productFilters} updateProductFilters={updateProductFilters} />

      <DataTable
        selectionMode='checkbox'
        selection={selectedProductsToRequestPcf}
        onSelectionChange={onSelectionChange}
        onSelectAllChange={onSelectAllChange}
        selectAll={isHeaderCheckboxSelected}
        isDataSelectable={isRowSelectable}
        disabled={isFetching}
        loading={isFetching}
        value={filteredProducts}
        scrollable
        pt={{
          thead: {
            className: 'z-2 !important',
          },
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
        className={styles['products-table']}
      >
        <Column
          selectionMode='multiple'
          header={t('bulkRequestPcfsDialog.selectAll')}
          headerStyle={{ width: '3rem' }}
        />
        <Column field='product_name' frozen className='z-1' body={productNameTemplate} />
        <Column field='product_cid' body={productIdTemplate} />
        <Column field='region' body={productRegionTemplate} />
      </DataTable>
    </Dialog>
  );
};

export default BulkRequestPcfsModal;
